export default {
  ready() {
    this.setNavMenuListeners();
    this.setSideNavToggles();
  },

  setSideNavToggles() {
    const sideNavToggles = document.querySelectorAll(
      this.selectors.sideNavToggles
    );
    const sideNavContainer = document.querySelector(
      this.selectors.sideNavContainer
    );

    sideNavToggles.forEach(function (toggle) {
      $(toggle).on("click", function () {
        $(sideNavContainer).toggleClass("visible");
        $("body").toggleClass("!tw-overflow-hidden");
      });
    });
  },

  setNavMenuListeners() {
    const navMenuItems = document.querySelectorAll(this.selectors.navMenuItems);

    navMenuItems.forEach(function (item) {
      $(item)
        .on("mouseenter", function () {
          item.classList.add("open");
        })
        .on("mouseleave", function () {
          item.classList.remove("open");
        });
    });
  },

  selectors: {
    navMenuItems: ".header-controls .dropdown",
    searchForm: ".header-controls form",
    sideNavContainer: ".side-nav--container",
    sideNavToggles: ".side-nav--toggle",
    signUpLink: ".header-controls .sign-up",
  },
};
